import React from "react";
import { Alert, Empty } from "antd";
import useTranslation from "../lang/useTranslation";
import { Title, Paragraph, Text } from "./typography";
import { mY } from "./spacingShorthands";
import { RemoteEditorProvider, useRemoteEditor } from "./remoteEditor";
import { useParams } from "./reach-router";
import Container from "./Container";
import ClimateFacts from "./ClimateFacts";

const Content = () => {
  const [{ name, facts, brand, live }] = useRemoteEditor();

  return (
    <div>
      {!live && (
        <Container css={mY(24)}>
          <Alert
            message="Warning: this product is not live"
            description="Only you can see this report. To make it public turn on live mode in this product settings."
            type="warning"
            showIcon
          />
        </Container>
      )}
      <Container size="lg" css={{ ...mY(36), textAlign: "center" }}>
        <Title>{name}</Title>
        <Paragraph>
          <span>{useTranslation("by")} </span>
          <Text strong>{brand.name}</Text>
        </Paragraph>
      </Container>
      <div css={mY(36)}>
        {facts ? (
          <ClimateFacts facts={facts} tonCo2Price={brand.tonCo2Price} />
        ) : (
          <Empty />
        )}
      </div>
    </div>
  );
};

export default () => (
  <RemoteEditorProvider path="products/details" params={useParams()}>
    <Content />
  </RemoteEditorProvider>
);

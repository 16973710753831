import React from "react";
import { Router, Redirect } from "../components/reach-router";
import page from "../components/page";
import { Content404 } from "./404";
import Report from "../components/Report";

export default page(() => (
  <Router
    css={{ flexGrow: 1, display: "flex", flexDirection: "column" }}
    basepath="/p"
  >
    <Redirect noThrow from="/" to="../" />
    <Report path="/:product" />
    <Content404 default />
  </Router>
));

import React, { useState, createContext, useContext } from "react";
import { useNProgress } from "./nProgress";
import { useApi } from "./api";
import { Content404 } from "../pages/404";

const RemoteEditorCtx = createContext();

export const useRemoteEditor = () => useContext(RemoteEditorCtx);

export const RemoteEditorProvider = ({ path, params, children }) => {
  const [edited, setEdited] = useState();
  const remote = useNProgress(useApi({ path, params }));
  if (!remote) return null;
  if (remote.error) return <Content404 />;
  return (
    <RemoteEditorCtx.Provider
      value={[
        edited || remote,
        (newState) => setEdited((oldEdited) => newState(oldEdited || remote)),
      ]}
    >
      {children}
    </RemoteEditorCtx.Provider>
  );
};

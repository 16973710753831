import React from "react";
import tinycolor from "tinycolor2";
import useTranslation from "../lang/useTranslation";
import { mY, pY } from "./spacingShorthands";
import Container from "./Container";
import mq from "./mq";
import { green } from "../theme/colors";

const Divider = ({ size = "sm" }) => (
  <div
    css={{
      height: { sm: 1, md: 4, lg: 8 }[size],
      backgroundColor: "black",
    }}
  />
);

const Amount = ({ value, label, className }) => (
  <div className={className}>
    <div
      css={{
        display: "flex",
        justifyContent: "space-between",
        ...pY(2),
      }}
    >
      <div>{label}</div>
      <div css={{ fontWeight: 900 }}>{value}</div>
    </div>
    <Divider />
  </div>
);

const KgCo2Amount = ({ value = 0, reference, label }) => {
  const percentStr = `${((100 * value) / (reference || 1)).toFixed(0)}%`;
  return (
    <Amount
      css={{
        position: "relative",
        zIndex: 0,
        "&::before": {
          content: '""',
          zIndex: -1,
          backgroundColor: tinycolor(green).setAlpha(0.4).toRgbString(),
          position: "absolute",
          top: 0,
          bottom: 0,
          right: 0,
          width: percentStr,
        },
      }}
      value={percentStr}
      label={
        <span>
          {label} {value.toFixed(2)}
          <span
            css={{
              fontSize: "0.7em",
              display: "inline-block",
              height: 0,
            }}
          >
            &nbsp;kgCO<sub>2</sub>e
          </span>
        </span>
      }
    />
  );
};

const MainMetric = ({ value = 0, unit, label }) => (
  <div>
    <Divider size="lg" />
    <div css={mY(4)}>
      <div>{label}</div>
      <div
        css={{
          display: "flex",
          justifyContent: "space-between",
          fontWeight: 900,
        }}
      >
        <div css={{ fontSize: 20 }}>{unit}</div>
        <div css={{ fontSize: 24 }}>{value}</div>
      </div>
    </div>
    <Divider size="md" />
    <div>&nbsp;</div>
    <Divider />
  </div>
);

export default ({
  facts: { manufacturing, rawMaterials, packaging, transportation },
  tonCo2Price,
  className,
}) => {
  const kgCo2e = manufacturing + rawMaterials + packaging + transportation;

  return (
    <Container size="sm" className={className}>
      <div
        css={[
          mq({ fontSize: [14, 16] }),
          {
            padding: 16,
            border: "1px solid",
            boxShadow: "0 10px 20px rgba(0,0,0,0.15)",
            lineHeight: "calc(1em + 4px)",
          },
        ]}
      >
        <div css={{ fontWeight: 900, fontSize: 24 }}>
          {useTranslation("climateFacts")}
        </div>
        <div css={mY(4)}>{useTranslation("perProduct")}</div>
        <MainMetric
          label={useTranslation("ghgEmissions")}
          value={kgCo2e.toFixed(2)}
          unit={
            <span>
              kgCO<sub>2</sub>e
            </span>
          }
        />
        <KgCo2Amount
          label={useTranslation("rawMaterials")}
          reference={kgCo2e}
          value={rawMaterials}
        />
        <KgCo2Amount
          label={useTranslation("transportation")}
          reference={kgCo2e}
          value={transportation}
        />
        <KgCo2Amount
          label={useTranslation("manufacturing")}
          reference={kgCo2e}
          value={manufacturing}
        />
        <KgCo2Amount
          label={useTranslation("packaging")}
          reference={kgCo2e}
          value={packaging}
        />
        {tonCo2Price && (
          <>
            <div>&nbsp;</div>
            <MainMetric
              label={useTranslation("climateAction")}
              unit={
                <span>
                  € {useTranslation("perTon")}CO<sub>2</sub>e
                </span>
              }
              value={tonCo2Price}
            />
            <Amount
              label={`${useTranslation("funding")} ${useTranslation(
                "perProduct"
              )}`}
              value={`${((kgCo2e * tonCo2Price) / 1000).toFixed(2)}€`}
            />
            <div css={{ ...mY(8), fontWeight: 900 }}>
              <strong>PUR Hexagone</strong>
            </div>
            <div>{useTranslation("purHexagoneDescription")}</div>
          </>
        )}
      </div>
      <div size="sm" css={mY(36)}>
        NB: All quantities of CO<sub>2</sub> shown above are actually quantities
        of CO
        <sub>2</sub> <em>equivalent</em> (hence the &quot;e&quot; at the end),
        which means they take into account other greenhouse gases.
      </div>
    </Container>
  );
};
